import { Component, ViewChild, DoCheck, Output, QueryList } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuoteService } from 'src/app/services/quote.service';
import { QuoteDto } from 'src/app/models/QuoteDto';
import { QuoteChildDto } from 'src/app/models/QuoteChildDto';
import { CodeValueDto } from '../CodeValueDto.Model';
import { DataService } from '../../services/data.service';
import { QuoteChildIssuerDto } from '../../models/QuoteChildIssuerDto';
import { AddDialogComponent } from './dialogs/add-dialog/add-dialog.component';
import { AddBatchDialogComponent } from './dialogs/add-batch-dialog/add-batch-dialog.component';
import { EditDialogComponent } from './dialogs/edit-dialog/edit-dialog.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { ElementRef, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { DealDto } from '../../order/DealDto.Model';
import { SalesDto } from '../../order/SalesDto.Model';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingsService } from 'src/app/app-settings.service';
import { QuoteData } from '../../models/QuoteData';
//import { tap } from 'rxjs/operators';
//import { map } from 'jquery';
import { map, tap } from 'rxjs/operators'
import { DialogService } from '../../services/dialog.service';
import { NotifResponseDto } from '../../models/NotifResponseDto';
import { SharedService } from '../../services/shared.service';
import { group } from 'console';
import { EditQuotedescComponent } from './dialogs/edit-quotedesc/edit-quotedesc.component';
import * as XLSX from 'xlsx';
import { Quote } from '@angular/compiler';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})

export class QuotesComponent implements OnInit {
  constructor(private appSettingsService: AppSettingsService,private quoteService: QuoteService, public httpClient: HttpClient, public dialog: MatDialog, private notifierService: NotifierService, public dataService: DataService, private router: Router, private dialogService: DialogService, private shared: SharedService) {
    this.quoteService.GetAllIssuer().subscribe(data => this.issuers = data,
      error => this.processError(error),
      () => console.log('Get all issuers complete'));
  }
  @Input() notifData: NotifResponseDto
  quotes: Array<QuoteDto> = [];
  @ViewChild('fileInput') fileInput;  

  quoteChilds: Array<QuoteChildDto> = [];
  quoteChildIssuers: Array<QuoteChildIssuerDto> = [];
  deals: Array<DealDto> = [];
  selectedQuote: QuoteDto;
  selectedQuoteChild: QuoteChildDto;
  selectedDeal: DealDto;
  //dataSource: MatTableDataSource<QuoteDto>;
  dataSource: QuoteData = null;
  dataSourceChild: MatTableDataSource<QuoteChildDto>;
  dataSourceDeal: MatTableDataSource<DealDto>;
  prevLength: number;
  prevDealLength: number;
  prevChildLength: number;
  issuersLength: number;
  pricesLength: number;
  lastNotifId: number = 0;
  numberOfNotif: number = 0;
  settings: any;
  issuers: CodeValueDto[];
  hide: boolean;
  isFromAction: boolean = false;
  group: string = "";
  groupEntity: SalesDto;
  allSales: SalesDto[];
  lastNotif: NotifResponseDto;
  dataLoaded: boolean = false;
  selectedChildIsSecondary: boolean = false;
  scopeValue: string = 'today';
  scopeTradeValue: string = 'today';
  sortStatus: string = '';
  sortActive: string = '';
  sortPriceDirectionSolvefor: string = '';
  filterValue: string = '';
  onBehalfSale: string = '';
  onBehalfSaleBook: string = '';
  hiddenQuotes: string = '';
  listIssuersPriced: Array<string> = [];
  pageEvent: PageEvent;
  liseuseDisplay = true;
  matsortdirection : "asc" | "desc" = "asc";

  displayedColumns: string[] = ['isin', 'title', 'quotenbr', 'client', 'requestdate', 'maturity', 'sales', 'currency', 'underlyings', 'size', 'side'/*, 'type'*/, 'status', 'actions'];
  displayedChildColumns: string[] = ['quotechildnbr', 'client', 'sales', 'trader', 'isin', 'title', 'side', 'size', 'currency', 'maturity', 'underlyings', 'solvefor', 'status', 'actions'];
  displayedDealColumns: string[] = ['dealnbr', 'quotechildnbr', 'client', 'trader', 'sales', 'side', 'isin', 'issuer', 'titre', 'maturity', 'size', 'currency', 'underlyings', 'status', 'actions'];

  //*******************************************
  displayedColumnPrices = (this.group == "trader") ? ['IssuerName', 'SolveFor', 'comment', 'counterpartid', 'autopricemessage', 'actions'] : ['IssuerName', 'SolveFor', 'comment', 'counterpartid', 'autopricemessage'];
  exampleDatabase: DataService | null;
  dataSourcePrice: MatTableDataSource<QuoteChildIssuerDto>;
  index: number;
  //totalSize: number = 100;
  id: number;
  issuerCode: string;
  specificSales: string = '';
  //@ViewChild(MatPaginator) paginatorPrice= new QueryList<MatPaginator>();
  //@ViewChild(MatSort, { static: true }) sortPrice: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;


  //*******************************************
  @ViewChild('pricesPaginator', { read: MatPaginator }) pricesPaginator: MatPaginator;
  @ViewChild('allPaginator', { read: MatPaginator }) allPaginator: MatPaginator;
  @ViewChild('tradesPaginator', { read: MatPaginator }) tradesPaginator: MatPaginator;
  @ViewChild('childsPaginator', { read: MatPaginator }) childsPaginator: MatPaginator;
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  @ViewChild('parentSort') parentSort: MatSort;
  @ViewChild('priceSort') priceSort: MatSort;

  isDragging = false;

  isVisibleQuotes = false;
  isVisibleQuoteChilds = false;
  isVisibleTrades = false;


 
  private toggleSidebar(isHide: boolean) {
    this.hide = isHide;
    this.dataService.hideSidebar.next({ isHidden: isHide });
  }

  ngOnInit() {
    this.hide = false;
    this.dataService.hideSidebar.next({ isHidden: this.hide });

    this.appSettingsService.getAll().subscribe((data: any[]) => {
      this.settings = data;
    });

    this.quoteService.GetAllQuotes(1, 50, 'today', '', '', '').pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(x => this.dataLoaded = true, error => this.processError(error));

    this.quoteService.GetGroupCode()
      .subscribe(
        x => {
          this.groupEntity = x;
          this.group = this.groupEntity.ApplicationGroupCode.replace("group.", "");
          this.quoteService.GetAllDeals(25, 0, this.scopeTradeValue, this.group)
            .subscribe(
              x => this.deals = x,
              error => this.processError(error)
            );
          error => this.processError(error)
        });
    this.quoteService.GetAllSales()
      .subscribe(
        x => {
          this.allSales = x;
          error => this.processError(error)
        });

    this.quoteService.GetHiddenQuotes()
      .subscribe(
        x => {
          this.hiddenQuotes = x != null ? x : '';
          error => this.processError(error)
        });

    this.prevLength = this.dataSource?.Data.length;
    this.prevChildLength = this.quoteChilds.length;
    this.issuersLength = (this.issuers) ? this.issuers.length : 0;

    if (this.dataSourcePrice) this.dataSourcePrice.sort = this.priceSort;
  }

  ngDoCheck() {
    this.quoteChilds = this.quoteChilds.filter(x => !x.IsDeleted);
    this.lastNotif = this.shared.getNotifications();
    if (this.lastNotif && (this.lastNotif.hasNewQuotes || this.lastNotif.hasNewQuoteChilds) && this.lastNotif.id > this.lastNotifId) {
      this.notifierService.notify("info", "Please Refresh");
      this.notifyAudio();
      this.lastNotifId = this.lastNotif.id;
      this.numberOfNotif++;
    }

    if (this.dataSource && this.dataSource.Data && this.dataSource.Data.length > 0) {
      this.dataSource.Data = this.dataSource.Data.filter(x => !x.IsDeleted);
      this.dataSource.Data.forEach(c => {
        if (!c.IsPrimaryQuote) c.Color = "#Df3aef";
        if (c.Status == "Booked" || c.Status == "Done to be booked") c.Color = "#3aef7a";
        if (c.Status == "Order sent") c.Color = "#306ef3";
        if (c.Status == "Cancelled") c.Color = "#F34e4e";

        c.QuoteChilds.forEach(qc => {
          if (qc.Status == "Order sent" || qc.Status == "Order received") {
            qc.Color = "#306ef3";
            if (((c.Status == "Price(s) sent" || c.Status == "Created") && this.group == 'sales' && this.groupEntity.SalesCode == qc.Sales) || this.group == 'trader' || this.group == 'super-user') c.Color = "#306ef3";
          }
          if (qc.Status == "Price(s) sent") qc.Color = "#3ae5ef";
          if (qc.Status == "Cancelled") qc.Color = "#F34e4e";
        })
      });
    }
    if (this.deals.length > 0) {
      this.deals.forEach(d => {
        if (d.Status == 'Order sent' || d.Status == 'Order received') d.Color = "#306ef3";
        if (d.Status == 'Booked' || d.Status == 'Done to be booked') d.Color = "#3aef7a";

      });
    }
    if (this.dataSource && this.dataSource.Data && this.dataSource.Data.length !== this.prevLength) {
      this.prevLength = this.dataSource.Data.length;
    }
    if (this.deals.length !== this.prevDealLength) {
      setTimeout(() => this.dataSourceDeal = new MatTableDataSource(this.deals));
      this.prevDealLength = this.deals.length;
    }
    if (this.quoteChilds.length !== this.prevChildLength) {
      setTimeout(() => this.dataSourceChild = new MatTableDataSource(this.quoteChilds));
      this.prevChildLength = this.quoteChilds.length;
    }
    if (this.quoteChildIssuers && this.quoteChildIssuers.length > 0) {
      this.listIssuersPriced = [];
      this.quoteChildIssuers.forEach((obj) => {
        this.listIssuersPriced.push(obj.IssuerCode);
      })
    }


    if (this.issuers && this.issuers.length !== this.issuersLength) {
      this.issuers = this.issuers.filter(i => i.IsOpen == true);
      this.issuersLength = this.issuers.length;
    }

    //if (this.issuers && this.issuers.length > 0 && this.listIssuersPriced && this.listIssuersPriced.length > 0) {
    //  this.issuers.forEach((obj) => {
    //    if (this.listIssuersPriced.includes(obj.Code)) obj.Selected = true;
    //  })
    //}

    if (this.dataSourcePrice) this.dataSourcePrice.sort = this.priceSort;
    //if (this.dataSource && this.dataSource.Data) this.dataSource.Data.sort = this.parentSort;

    this.displayedColumnPrices = (this.group == "trader" || this.group == "super-user") ? ['IssuerName', 'SolveFor', 'comment', 'counterpartid', 'autopricemessage', 'actions'] : ['IssuerName', 'SolveFor', 'comment', 'LastUpdate'];

  }
  //public doFilter = (value: string) => {
  //  this.dataSource.filter = value.trim().toLocaleLowerCase();
  //}

  onPaginateChange(event: PageEvent) {
    //this.dataSource.Data = [];
    let page = event.pageIndex;
    let size = event.pageSize;
    page = page + 1;

    this.quoteService.GetAllQuotes(page, size, this.scopeValue, this.filterValue, this.sortStatus, this.sortActive, this.specificSales).pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(res => { }, error => this.processError(error));
  }

  notifyAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/alarm.mpeg";
    audio.load();
    audio.play();
  }

  displayQuotes() {
    this.isVisibleQuotes = !this.isVisibleQuotes;
  }
  displayQuoteChilds() {
    this.isVisibleQuoteChilds = !this.isVisibleQuoteChilds;
  }
  displayTrades() {
    this.isVisibleTrades = !this.isVisibleTrades;
  }
  cleanSelectionRows(isParent) {
    if (isParent) {
      this.dataSource.Data.forEach(c => {
        c.Selected = false;
      });
      //setTimeout(() => this.dataSource = new MatTableDataSource(this.quotes));
    }
    else {
      this.quoteChilds.forEach(c => {
        c.Selected = false;
      });
      setTimeout(() => this.dataSourceChild = new MatTableDataSource(this.quoteChilds));
    }
  }
  cleanTradeSelectionRows() {
    this.deals.forEach(c => {
      c.Selected = false;
    });
    this.dataSourceDeal = new MatTableDataSource(this.deals);
  }
    OnSelect(element) {
    if (this.isFromAction) {
      this.isFromAction = false;
      return;
    }
    this.quoteChildIssuers = [];
    this.selectedChildIsSecondary = false;
    this.cleanSelectionRows(false);
    //this.deals = [];
    this.selectedQuoteChild = null;
    if (element.Selected) {
      element.Selected = false;
      this.quoteChilds = [];
      setTimeout(() => this.dataSourceChild = new MatTableDataSource(element.QuoteChilds));
      this.selectedQuoteChild = null;
      this.selectedQuote = null;
    }
    else {
      this.cleanSelectionRows(true);
      element.Selected = true;
      this.selectedQuote = element;
      this.quoteChilds = element.QuoteChilds.filter(i => i.IsVisible == true);

      this.cleanTradeSelectionRows();
      this.selectedDeal = null;
      this.cleanSelectionRows(false);
      this.quoteChilds.sort((a, b) => a.QuoteChildNbr.localeCompare(b.QuoteChildNbr));
      this.quoteChilds[this.quoteChilds.length - 1].Selected = true;
      this.selectedQuoteChild = this.quoteChilds[this.quoteChilds.length - 1];
      this.selectedQuoteChild.QuoteChildIssuers = [];

      this.RefreshPrices(this.selectedQuoteChild, false);

      this.refreshSolveForValue(this.selectedQuoteChild);
      this.pricesLength = this.selectedQuoteChild.QuoteChildIssuers.length;

      this.quoteChildIssuers = this.selectedQuoteChild.QuoteChildIssuers;
      this.ngAfterViewInit();
      this.selectedChildIsSecondary = !element.IsPrimaryQuote;
      //this.deals = element.Deals;
      this.dataSourcePrice = new MatTableDataSource(this.selectedQuoteChild.QuoteChildIssuers);
      //this.dataSourceDeal = new MatTableDataSource(element.Deals);
      this.dataSourcePrice.paginator = this.pricesPaginator;

      if (this.selectedQuoteChild.SolveFor == "Coupon") {
        this.matsortdirection = 'desc';
      }
      else this.matsortdirection = 'asc';
    }

  }
  disableSelection() {
    this.isFromAction = true;
  }

  openProduct(isin) {
    var link = '';
    link = "productedit/" + isin;
    window.open(link, "_blank");

  }
  openDeal(dealnbr) {
    var link = '';
    link = "deals/structured/booking/" + dealnbr;
    window.open(link, "_blank");

  }


  refreshSolveForValue(element) {
    element.QuoteChildIssuers.forEach(c => {
      if (c.PriceOff == true) c.SolveForValue = "Off";
      else if (c.AutoPrice == true && !c.SolveFor) c.SolveForValue = "Auto Pricer";
      else c.SolveForValue = c.SolveFor;
    });
  }

  OnSelectChild(element) {
    if (this.isFromAction) {
      this.isFromAction = false;
      return;
    }
    this.quoteChildIssuers = [];
    this.selectedChildIsSecondary = false;
    if (element.Selected) {
      element.Selected = false;
      this.selectedQuoteChild = null;
    }
    else {
      this.cleanTradeSelectionRows();
      this.selectedDeal = null;
      this.cleanSelectionRows(false);
      element.Selected = true;
      this.selectedQuoteChild = element;
      element.QuoteChildIssuers = [];
      this.RefreshPrices(element, false);

      this.refreshSolveForValue(element);
      this.pricesLength = this.selectedQuoteChild.QuoteChildIssuers.length;

      this.quoteChildIssuers = element.QuoteChildIssuers;
      this.ngAfterViewInit();
      this.selectedChildIsSecondary = !element.Quote.IsPrimaryQuote;
      //this.deals = element.Deals;
      this.dataSourcePrice = new MatTableDataSource(element.QuoteChildIssuers);
      //this.dataSourceDeal = new MatTableDataSource(element.Deals);
      this.dataSourcePrice.paginator = this.pricesPaginator;

      if (element.SolveFor == "Coupon") {
        this.matsortdirection = 'desc';
      }
      else this.matsortdirection = 'asc';

    }

  }

  OnSelectDeal(element) {
    if (this.isFromAction) {
      this.isFromAction = false;
      return;
    }
    this.quoteChildIssuers = [];
    if (element.Selected) {
      element.Selected = false;
      this.selectedDeal = null;
    }
    else {
      this.selectedQuoteChild = null;
      this.cleanSelectionRows(false);
      this.cleanTradeSelectionRows();
      element.Selected = true;
      this.selectedDeal = element;
    }

  }

  ExportToExcel(quoteNbr: string) {
    this.quoteService.ExportToExcel(quoteNbr).subscribe((res: Blob) => {
      const url = window.URL.createObjectURL(res);
      const a = document.createElement('a');
      a.href = url;
      a.download = `data.xlsx`;
      document.body.appendChild(a); 
      a.click(); 
      document.body.removeChild(a); 
      window.URL.revokeObjectURL(url); 
    });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    this.isDragging = false;
  }

   onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer?.files.length) {
      const file = event.dataTransfer.files[0];

      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
        this.UploadFileExcel(file); 
      }
      else if (file.type === 'application/json') {
        this.UploadFileJson(file);
      }

    }
  }

  UploadFileExcel(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    this.quoteService.UploadExcel(formData).subscribe(
      (result: any[]) => {
        console.log('imported:', result);
        this.notifierService.notify("success", "Quote Imported");
        this.RefreshQuotes();
      },
      (error) => {
        console.error('Upload failed:', error);
        var errorMessage = error?.error.text ? error?.error.text : "Failed to import";
        this.notifierService.notify("error", errorMessage);
      }
    );
  }
  UploadFileJson(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    this.quoteService.UploadQuoteJson(formData).subscribe(
      (result: any[]) => {
        console.log('imported:', result);
        this.notifierService.notify("success", "Quote Imported");
        this.RefreshQuotes();
      },
      (error) => {
        console.error('Upload failed:', error);
        var errorMessage = error?.error.text ? error?.error.text : "Failed to import";
        this.notifierService.notify("error", errorMessage);
      }
    );
  }


  getQuoteChildNbr(quoteChilds: QuoteChildDto[]): string {
    return quoteChilds.map(elm => {
      return `${elm.QuoteChildNbr}/${elm.ISIN}/${elm.Quote.Product.Underlyings}`;
    }).join('\n'); 
  }

  getTooltipContent(description: string): string {
    return description.replace(/<br>/g, '\n');
  }
  cloneQuote(element) {
    if (element.IsPrimaryQuote) {
      var link = "/productdraftedit/clone\?clonefrom=" + element.QuoteNbr + "-001";
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
    else {
      var link = "/quoting/rfq\?clonefrom=" + element.QuoteNbr + "-001";
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
  }
  cloneQuoteChild(element) {
    if (element.Quote.IsPrimaryQuote) {
      var link = "/productdraftedit/clone\?clonefrom=" + element.QuoteChildNbr + "child";
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
    else {
      var link = "/quoting/rfq\?clonefrom=" + element.QuoteChildNbr + "child";
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
  }

  modifyQuote(element) {
    if (element.Quote.IsPrimaryQuote) {
      var link = "/productdraftedit/" + element.QuoteChildNbr;
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
    else {
      var link = "/quoting/rfq/" + element.QuoteChildNbr;
      //this.router.navigateByUrl(link);
      window.open(link, "_blank");
    }
  }
  deletequote(element, fromQuote) {
    if (fromQuote) {
      element.IsDeleted = true;
      this.quoteService.DeleteQuotes(element.QuoteNbr, true).subscribe(x => {
        this.notifierService.notify("success", "Element deleted"), error => this.processError(error);
        this.RefreshQuotes();


      });

    } else {
      let isOrphan;
      this.quoteService.IsOrphan(element.Quote.QuoteNbr).subscribe((x) => {
        isOrphan = x;
        if (isOrphan) {
          element.Quote.IsDeleted = true;
          this.quoteService.DeleteQuotes(element.Quote.QuoteNbr, true).subscribe(x => {
            this.notifierService.notify("success", "Element deleted"), error => this.processError(error);
            this.RefreshQuotes();
          });

        } else {
          element.IsDeleted = true;
          this.quoteService.DeleteQuotes(element.QuoteChildNbr, false).subscribe(x => {
            this.notifierService.notify("success", "Element deleted"), error => this.processError(error);
            this.RefreshQuotes();
          });
        }
      });
    }
  }
  sendOrder(element) {
    var link = '';
    if (element.Title == 'MiniFuture' || element.Title == 'Minifuture') {
      link = "order/" + element.QuoteChildNbr;
    }
    else {
      link = "deal/new/" + element.QuoteChildNbr;

      if (this.onBehalfSale) link = link + "/" + this.onBehalfSale;
    }
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }

  bookDeal(element) {
    var link = "booking/" + element.OrderId;
    if (this.onBehalfSaleBook) link = link + "/" + this.onBehalfSaleBook;
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }

  doneDeal(element) {

    if (element.Title == 'MiniFuture' || element.Title == 'Minifuture') {
      link = "done/deal/minifut/" + element.OrderId;
    }
    else {
      var link = "done/deal/" + element.OrderId;
    }
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }
  editOrder(element) {


    var link = "order/edit/" + element.Deals[0].OrderId;
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }
  createMinifuture(element) {
    var link = "deDraft/" + element.QuoteChildNbr;
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }


  addSize(element) {
    if (element.Title == 'MiniFuture') {

      this.quoteService.AddSize(element.QuoteChildNbr).subscribe(r => {
        var link = "/order/addSize/" + r.QuoteChildNbr;
        window.open(link, "_blank");
      });

    } else {

      this.quoteService.AddSize(element.QuoteChildNbr).subscribe(r => {
        var link = "/deal/new/addSize/" + r.QuoteChildNbr;
        window.open(link, "_blank");
      });
    }

  }
  arborescence(element) {
    var link = "deals/" + element.DealNbr;
    window.open(link, "_blank");

  }
  automatedPricer(element) {
    var link = "pricing/" + element.QuoteChildNbr;
    //this.router.navigateByUrl(link);
    window.open(link, "_blank");
  }
  amendToMarket(element) {
    this.quoteService.AmendToMarket(element).subscribe(c => {
      var quoteCancelled = c;
      this.notifierService.notify("success", "Amend To Market executed")
    },
      error => this.processError(error));
  }
  cancelQuote(element) {
    this.dialogService.openConfirmDialog("Are you sure that you want to cancel ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.quoteService.SetQuoteCancelled(element).subscribe(c => {
            var quoteCancelled = c;
            this.selectedQuote.Status = 'Cancelled';
            this.selectedQuote.Color = '#F34e4e';
            element.Status = 'Cancelled';
            element.Color = '#F34e4e';
            this.selectedQuoteChild.Status = 'Cancelled';
            this.selectedQuoteChild.Color = '#F34e4e';
            //this.refreshQuotes();
            this.notifierService.notify("success", "Quote successfully cancelled")
          },
            error => this.processError(error));
        }
      });
  }
  cancelOrder(element) {

    this.dialogService.openConfirmDialog("Are you sure that you want to cancel ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.quoteService.SetOrderCancelled(element).subscribe(c => {
            var quoteCancelled = c;
            element.Status = "Cancelled"
            this.notifierService.notify("success", "Order successfully cancelled")
          },
            error => this.processError(error));
        }
      });



  }

  setQuoteToUser(element) {
    this.quoteService.SetQuoteToUser(element).subscribe(c => {
      var quoteRequested = c;
      if (element.Status == 'UNACK') {
        this.selectedQuote.Status = 'Requested';
        element.Status = 'Requested';
      }
      element.Trader = c.Trader;
      element.Sales = c.Sales;
      //this.refreshQuotes();
      this.notifierService.notify("success", "Quote successfully requested")
    },
      error => this.processError(error));
  }
  setOrderReceived(element) {
    this.quoteService.setOrderReceived(element).subscribe(c => {
      var quoteRequested = c;
      element.Status = 'Order received';


      this.quoteService.GetAllDeals(25, 0, this.scopeTradeValue, this.group)
        .subscribe(
          x => this.deals = x,
          error => this.processError(error)
        );

      this.notifierService.notify("success", "Order Set Received")
    },
      error => this.processError(error));
  }
  SetStatusRequested(element) {
    this.quoteService.SetStatusRequested(element).subscribe(c => {
      var quoteRequested = c;
      element.Status = 'Requested';
      this.selectedQuote.Status = 'Requested';
      this.selectedQuoteChild.Status = 'Requested';

      this.notifierService.notify("success", "Quote Set Requested")
    },
      error => this.processError(error));
  }

  getQuotesFromLiseuse() {
    
    this.quoteService.GetAllQuotes(1, this.paginator.pageSize, this.scopeValue, this.liseuseDisplay?"":"<>Liseuse", this.sortStatus, this.sortActive).pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(res => { }, error => this.processError(error));
  }

  getQuotesFromSearch(search) {
    this.liseuseDisplay = true;
    this.quoteService.GetAllQuotes(1, this.paginator.pageSize, this.scopeValue, search, this.sortStatus, this.sortActive, this.specificSales).pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(res => { }, error => this.processError(error));
  }
  getQuotesFromScope(scope) {
    this.liseuseDisplay = true;
    this.quoteService.GetAllQuotes(1, this.paginator.pageSize, scope, this.filterValue, this.sortStatus, this.sortActive).pipe(
        tap(quotes => console.log(quotes)),
        map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(res => { }, error => this.processError(error));
  }

  onSaleChange() {
    this.liseuseDisplay = true;

      this.quoteService.GetAllQuotes(1,this.paginator.pageSize,this.scopeValue,this.filterValue,this.sortStatus,this.sortActive,this.specificSales).pipe(
        tap(quotes => console.log(quotes)),
        map((quoteData: QuoteData) => this.dataSource = quoteData)
      ).subscribe(res => { },error => this.processError(error));
    
  }

  getTradesFromScope(scope) {
    this.liseuseDisplay = true;
    this.quoteService.GetAllDeals(25, 0, scope, this.group)
      .subscribe(
        x => this.deals = x
      );
    setTimeout(() => this.dataSourceDeal = new MatTableDataSource(this.deals));

  }
  RefreshQuotes() {
    this.liseuseDisplay = true;
    this.cleanSelectionRows(false);
    this.selectedQuoteChild = null;
    this.selectedDeal = null;
    this.quoteChilds = [];

    var self = this;
    this.quoteService.GetAllQuotes(this.paginator.pageIndex + 1, this.paginator.pageSize, this.scopeValue, this.filterValue, this.sortStatus, this.sortActive).pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) => this.dataSource = quoteData)
    ).subscribe(x => {
      this.notifierService.notify("success", "Data successfully refreshed");
      this.numberOfNotif = 0;
    });
    this.quoteService.GetAllDeals(25, 0, this.scopeTradeValue, this.group)
      .subscribe(
        x => this.deals = x
      );
    setTimeout(() => this.dataSourceDeal = new MatTableDataSource(this.deals));
  }

  RefreshPrices(quoteChild, fromReadMail = true) {
    let self = this;
    this.quoteService.RefreshPrices(quoteChild).subscribe(q => {
      console.log(q);
      self.quoteChildIssuers = q.QuoteChildIssuers;
      quoteChild.quoteChildIssuers = q.QuoteChildIssuers;
      self.quoteChildIssuers.forEach(c => {
        delete c.$id;
        if (c.PriceOff == true) c.SolveForValue = "Off";
        else if (c.AutoPrice == true) c.SolveForValue = "Auto Pricer";
        else c.SolveForValue = c.SolveFor;
      });
      if (fromReadMail) self.notifierService.notify("success", "Prices successfully refreshed");

      if (!fromReadMail) {
        var splitedqcnbr = quoteChild.QuoteChildNbr.split("-");
        var quotenbr = splitedqcnbr[0] + "-" + splitedqcnbr[1];

        this.dataSource.Data.map(obj => {
          console.log(obj);
          if (obj.QuoteNbr == quotenbr) {
            obj.QuoteChilds.map(qc => {
              if (qc.QuoteChildNbr == quoteChild.QuoteChildNbr) {
                qc.QuoteChildIssuers = [];
                qc.QuoteChildIssuers = self.quoteChildIssuers;
              }
            })
          }
        })
      }
      self.refreshTable();

    },
      error => this.processError(error)
    );
  }


  ReadAllMail() {
    var self = this;
    self.notifierService.notify("success", "Read all Prices started");
    var dataSourceQuote = this.dataSource.Data;
    var quoteChildToSend = [];
    for (let i = 0; i < dataSourceQuote.length; i++) {
      var quoteChilds = dataSourceQuote[i].QuoteChilds;
      for (let j = 0; j < quoteChilds.length; j++) {
        quoteChildToSend.push(quoteChilds[j]);
      }
    }
    var done = false;
      this.quoteService.ReadAllMail(quoteChildToSend).subscribe(q => {
        var quoteRequested = q;
        for (let i = 0; i < quoteRequested.length; i++) {
          self.notifierService.notify("success", "Read my mail executed");
        }
        if (!done)
        {
          this.RefreshPrices(this.selectedQuoteChild);
          done = true;
        };
        },
        e => self.processError(e));
   
  }

  ReadMail() {
    var self = this;
    if (this.selectedQuoteChild) {
      this.quoteService.ReadMail(this.selectedQuoteChild).subscribe(q => {
        var quoteRequested = q;
        self.notifierService.notify("success", "Read my mail executed");
        self.RefreshPrices(this.selectedQuoteChild);
      },
        e => self.processError(e));
    }
    else {
      this.notifierService.notify("error", "there is no Child selected !");
    }
  }
  SendPrice() {
    var self = this;
    if (this.selectedQuoteChild) {
      this.selectedQuoteChild.QuoteChildIssuers.forEach(element => element.$id=null);
      this.quoteService.SendPrice(this.selectedQuoteChild).subscribe(q => {
        delete q.$id;
        self.selectedQuoteChild.Status = q.Status;
        self.selectedQuote.Status = q.Status;
        self.notifierService.notify("success", "Price successfully sent");
        //this.refreshQuotes();
      }, error => this.processError(error));
    }
    else {
      this.notifierService.notify("error", "there is no Child selected !");
    }
  }
  hideQuote(element) {
    var self = this;
    if (element) {
      this.quoteService.HideQuote(element.QuoteNbr).subscribe(q => {
        self.hiddenQuotes = q;
        self.notifierService.notify("success", "Quote successfully hid");

      }, error => this.processError(error));
    }
    else {
      this.notifierService.notify("error", "can't hide quote !");
    }
  }
  ResetHiddenQuotes() {
    var self = this;
    this.liseuseDisplay = true;
    this.quoteService.ResetHiddenQuotes().subscribe(q => {
      self.hiddenQuotes = q;
      self.notifierService.notify("success", "Hidden quotes successfully reseted");
    }, error => this.processError(error));
  }
  addNew() {
    var QuoteChildId = this.selectedQuoteChild.Id;
    var IssuerCode = null;
    var SolveFor = null;
    var Comment = null;
    var PriceOff = false;
    var off = "Off";
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: { QuoteChildId: QuoteChildId, IssuerCode: IssuerCode, SolveFor: SolveFor, PriceOff: PriceOff, Comment: Comment, off: off, issuers: this.issuers }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

        var existingIssuers = [];
        if (this.quoteChildIssuers && this.quoteChildIssuers.length > 0) {
          this.quoteChildIssuers.forEach(function (qci) {
            existingIssuers.push(qci.IssuerCode);
          })
        }

        var addedPrice = this.dataService.getDialogData();
        var issuer = this.issuers.filter(i => i.Code == addedPrice.IssuerCode);
        addedPrice.IssuerName = issuer[0].Description;

        if (existingIssuers.indexOf(addedPrice.IssuerCode) >= 0) {
          this.notifierService.notify("warning", "Issuer \"" + addedPrice.IssuerName + "\" already exist !");
          return;
        }
        //Save database
        this.quoteService.AddQuoteChildIssuer(addedPrice).subscribe(c => {
          addedPrice = c;
          //this.notifierService.notify("success", "Price successfully added")
          this.quoteChildIssuers.push(addedPrice);

          this.quoteChildIssuers.forEach(c => {
            if (c.PriceOff) c.SolveForValue = "Off";
            else c.SolveForValue = c.SolveFor;
          });

          //refresh table

          this.refreshTable();
          if (this.selectedQuoteChild.Status == 'UNACK') {
            this.selectedQuote.Status = 'Requested';
            this.selectedQuoteChild.Status = 'Requested';
          }
          //this.refreshQuotes();
          //self.updateDealCatcher();
        },
          error => this.processError(error));

      }
    }, error => this.processError(error));
  }

  addNewBatch() {
    var QuoteChildId = this.selectedQuoteChild.Id;
    var IssuerCode = null;
    var SolveFor = null;
    var Comment = null;
    var PriceOff = false;

    var existingIssuers = [];
    var changingIssuers = [];
    if (this.quoteChildIssuers && this.quoteChildIssuers.length > 0) {
      this.quoteChildIssuers.forEach(function (qci) {
        existingIssuers.push(qci.IssuerCode);
      })
    }
    this.issuers.forEach(function (obj) {
      if (existingIssuers.indexOf(obj.Code) >= 0) {
        obj.Selected = true;
      }
      else
        obj.Selected = false;
    });
    const dialogRef = this.dialog.open(AddBatchDialogComponent, {
      data: { QuoteChildId: QuoteChildId, IssuerCode: IssuerCode, SolveFor: SolveFor, PriceOff: PriceOff, Comment, issuers: this.issuers }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

        var listAddedPrice = this.dataService.getDialogData();
        if (listAddedPrice && listAddedPrice.length > 0) {
          var scope = this;

          listAddedPrice.forEach(function (lap) {
            changingIssuers.push(lap.IssuerCode);
          })

          //List to Add
          for (var i = 0; i < listAddedPrice.length; i++) {

            var issuer = scope.issuers.filter(j => j.Code == listAddedPrice[i].IssuerCode);
            listAddedPrice[i].IssuerName = issuer[0].Description;


            if (existingIssuers.indexOf(listAddedPrice[i].IssuerCode) == -1) {
              //scope.notifierService.notify("warning", "Issuer \"" + listAddedPrice[i].IssuerName + "\" already exist !");
              //continue;

              var added = listAddedPrice[i];
              //Save database
              scope.quoteService.AddQuoteChildIssuer(listAddedPrice[i]).subscribe(c => {
                added = c;
                //scope.notifierService.notify("success", "Price successfully added")
                this.quoteChildIssuers.push(added);

                this.quoteChildIssuers.forEach(c => {
                  if (c.PriceOff) c.SolveForValue = "Off";
                  //  else c.SolveForValue = c.SolveFor;
                });

                this.refreshTable();
                //this.RefreshPrices(this.selectedQuoteChild, false);

                //self.updateDealCatcher();
              },
                e => {
                  scope.notifierService.notify("error", "can't save Price for" + listAddedPrice[i].IssuerName);
                  this.processError(e);
                });
            }

          }
          //List to Delete
          for (var i = 0; i < this.quoteChildIssuers.length; i++) {

            if (changingIssuers.indexOf(this.quoteChildIssuers[i].IssuerCode) == -1) {


              var deleted = this.quoteChildIssuers[i];
              //Save database
              this.quoteService.DeleteQuoteChildIssuer(deleted).subscribe(c => {
                deleted = c;
                this.quoteChildIssuers.push(deleted);

                this.quoteChildIssuers = this.quoteChildIssuers.filter(i => i.Id !== deleted.Id);
                this.refreshTable();
                //self.updateDealCatcher();
              },
                e => {
                  scope.notifierService.notify("error", "can't delete Price for" + this.quoteChildIssuers[i].IssuerCode);
                  this.processError(e);
                });
            }

          }

        }
        if (this.selectedQuoteChild.Status == 'UNACK') {
          this.selectedQuote.Status = 'Requested';
          this.selectedQuoteChild.Status = 'Requested';
        }
        //this.refreshQuotes();

        //this.quoteChildIssuers.concat(listAddedPrice);
        //refresh table

      }
    }, error => this.processError(error));
  }

  startEdit(i: number, element: QuoteChildIssuerDto, Id: string, IssuerCode: string, IssuerName: string, SolveFor: number, Comment: string, PriceOff: boolean) {
    this.issuerCode = IssuerCode;
    var off = "Off";
    // index row is used just for debugging proposes and can be removed
    this.index = i;
    console.log(this.index);
    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: { Id: Id, IssuerCode: IssuerCode, IssuerName: IssuerName, SolveFor: SolveFor, Comment: Comment, PriceOff: PriceOff, off: off, issuers: this.issuers }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {


        var existingIssuers = [];
        if (this.quoteChildIssuers && this.quoteChildIssuers.length > 0) {
          this.quoteChildIssuers.forEach(function (qci) {
            if (qci.Id != Id) existingIssuers.push(qci.IssuerCode);
          })
        }

        var editedPrice = this.dataService.getDialogData();
        var issuer = this.issuers.filter(i => i.Code == editedPrice.IssuerCode);
        editedPrice.IssuerName = issuer[0].Description;


        if (existingIssuers.indexOf(editedPrice.IssuerCode) >= 0) {
          this.notifierService.notify("warning", "Issuer \"" + editedPrice.IssuerName + "\" already exist !");
          return;
        }
        //Save database
        this.quoteService.UpdateQuoteChildIssuer(editedPrice).subscribe(c => {
          editedPrice = c;
          //this.notifierService.notify("success", "Price updated")
          //self.updateDealCatcher();
        },
          error => this.processError(error));
        this.quoteChildIssuers = this.quoteChildIssuers.filter(i => i.IssuerCode !== this.issuerCode);
        this.quoteChildIssuers.push(editedPrice);

        this.dataSource.Data.forEach(q => {
          q.QuoteChilds.forEach(qc => {
            if (qc.Id == element.QuoteChildId) {
              qc.QuoteChildIssuers = [];
              qc.QuoteChildIssuers = this.quoteChildIssuers;
            }
          });
        });

        this.quoteChildIssuers.forEach(c => {
          if (c.PriceOff) c.SolveForValue = "Off";
          else c.SolveForValue = c.SolveFor;
        });
        //refresh table
        this.refreshTable();
        if (this.selectedQuoteChild.Status == 'UNACK') {
          this.selectedQuote.Status = 'Requested';
          this.selectedQuoteChild.Status = 'Requested';
        }
        //this.refreshQuotes();
      }
    }, error => this.processError(error));
  }


  startEditDescription(element: QuoteChildDto) {
    // index row is used just for debugging proposes and can be removed
    console.log(this.index);
    const dialogRef = this.dialog.open(EditQuotedescComponent, {
      data: { quoteChild: this.selectedQuoteChild }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

        var editedDescription = this.dataService.getDialogData();

        //Save database
        this.quoteService.UpdateQuoteChildDescription(editedDescription.quoteChild).subscribe(c => {
          editedDescription = c;
          this.selectedQuoteChild.Description = editedDescription.Description;
          //this.notifierService.notify("success", "Price updated")
          //self.updateDealCatcher();
        },
          error => this.processError(error));

      }
    }, error => this.processError(error));
  }
  deleteItem(i: number, Id: string) {
    this.index = i;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { Id: Id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        var deletedPrice = this.dataService.getDialogData();

        //Save database
        this.quoteService.DeleteQuoteChildIssuer(deletedPrice).subscribe(c => {
          deletedPrice = c;
          //this.notifierService.notify("success", "Price successfully deleted")
          //self.updateDealCatcher();
        },
          error => this.processError(error));
        this.quoteChildIssuers = this.quoteChildIssuers.filter(i => i.Id !== deletedPrice.Id);
        //refresh table
        this.refreshTable();
      }
    }, error => this.processError(error));
  }
  noResponse() {
  }

  private refreshTable() {
    setTimeout(() =>
      this.dataSourcePrice = new MatTableDataSource(this.quoteChildIssuers),
    );
  }

  private refreshQuotes() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  ngAfterViewInit() {
    if (this.dataSourcePrice) this.dataSourcePrice.sort = this.priceSort;
  }
  sortData(sort: Sort) {
    this.sortPriceDirectionSolvefor = sort.direction;
  }

  sortParentData(sort: Sort) {

    this.sortStatus = sort.direction;
    this.sortActive = sort.active;
    this.quoteService.GetAllQuotes(this.paginator.pageIndex + 1, this.paginator.pageSize, this.scopeValue, this.filterValue, this.sortStatus, this.sortActive).pipe(
      tap(quotes => console.log(quotes)),
      map((quoteData: QuoteData) =>
        this.dataSource = quoteData)
    ).subscribe(res => { }, error => this.processError(error));
  }

  // Display error to user
  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }

}
