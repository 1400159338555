<link href="https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet">
<!--<div *ngIf="!quotes.length">
  Loading...-->
<div style="overflow:auto">
  <div class="example-loading-shade" *ngIf="!dataLoaded">
    <mat-spinner *ngIf="!dataLoaded"></mat-spinner>
    <!--<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      GitHub's API rate limit has been reached. It will be reset in one minute.
    </div>-->
  </div>
  <!--<pre>Profile : {{group}}</pre>-->
<div class="row" style="display: flex; flex-wrap: nowrap;">
  <div class="col-md-2 HeaderStyle">
    <mat-form-field fxFlex="40%">
      <input matInput type="text" (keyup)="getQuotesFromSearch($event.target.value)" filterValue="filterValue"
             [(ngModel)]="filterValue" placeholder="Search">
    </mat-form-field>
  </div>
  <div class="col-md-2 HeaderStyle">
    <mat-form-field appearance="fill">
      <mat-label>Quotes Scope</mat-label>
      <select matNativeControl (change)="getQuotesFromScope(scopeValue)" [(ngModel)]="scopeValue">
        <option value="today">Today's quotes</option>
        <option value="yesterday">Yesterday's quotes</option>
        <option value="onlyMine">My quotes</option>
        <option value="allQuotes">All quotes</option>
        <!--<option value="specificSales">Specific Sales</option>-->
      </select>
    </mat-form-field>
  </div>
  <div class="col-md-2" *ngIf="scopeValue === 'specificSales'">
    <mat-form-field appearance="fill">
      <mat-label>Select Sale:</mat-label>
      <select matNativeControl [(ngModel)]="specificSales" (change)="onSaleChange()">
        <option *ngFor="let sale of allSales" [value]="sale.SalesCode">{{ sale.SalesCode }}</option>
      </select>
    </mat-form-field>

  </div>
  <div class="col-md-2 HeaderStyle">
    <mat-form-field appearance="fill">
      <mat-label>Trades Scope</mat-label>
      <select matNativeControl filterValue="scopeTradeValue" (change)="getTradesFromScope(scopeTradeValue)"
              [(ngModel)]="scopeTradeValue">
        <option value="today">Today's trades</option>
        <option value="allTrades">All trades</option>
      </select>
    </mat-form-field>
  </div>
  <div class="col-md-2" style="display:flex; margin-left:50px; height:35px;">
    <button *ngIf="numberOfNotif==0" mat-raised-button color="primary" style=" width: 150px;" (click)="RefreshQuotes()">Refresh Data</button>

    <button *ngIf="numberOfNotif>0" mat-raised-button color="primary" matBadge="{{numberOfNotif}}" matBadgePosition="before" matBadgeColor="accent" style="width: 150px;" (click)="RefreshQuotes()">Refresh Data</button>

    <button *ngIf="hiddenQuotes==''" mat-raised-button style="background-color: darkgray; margin-left: 10px;" (click)="ResetHiddenQuotes()" title="Reset Hidden Quotes">
      <i class="fa fa-undo"></i>
    </button>

    <button *ngIf="hiddenQuotes !==''" mat-raised-button color="primary" (click)="ResetHiddenQuotes()" title="Reset Hidden Quotes">
      <i class="fa fa-undo"></i>
    </button>
  </div>


  <div class="col-sm-1 HeaderStyle" *ngIf="selectedQuoteChild && settings.IsUss && (group =='trader' || group =='super-user')">
    <button mat-raised-button color="primary" (click)="ReadAllMail()">Read All Prices</button>
  </div>

  <div class="col-sm-1 HeaderStyle" *ngIf="selectedQuoteChild && settings.IsUss && (group =='trader' || group =='super-user')">
    <button mat-raised-button color="primary" (click)="ReadMail()">Read Quote</button>
  </div>

  <div class="col-md-1  HeaderStyle" *ngIf="selectedQuoteChild && (group =='trader' || group =='super-user')" [disabled]="group =='trader' && selectedQuoteChild && selectedQuoteChild.Status =='Order sent'">
    <button mat-raised-button color="primary" (click)="SendPrice()">Send Price(s)</button>
  </div>
</div>
  <div class="row">
    <div *ngIf="dataLoaded">
      <!-- Blotter parent (Quote) -->
      <div class="col-sm">
        <div class="row" style="display:flex;">
          <h4>Blotter parent</h4>
          <button mat-button  (click)="displayQuotes()">
            <mat-icon *ngIf="isVisibleQuotes">expand_more</mat-icon>
            <mat-icon *ngIf="!isVisibleQuotes">expand_less</mat-icon>
          </button>
        </div>
        <label class="col-sm">
          <div *ngIf="settings.IsUss">
            <input type="checkbox" [(ngModel)]="liseuseDisplay" (change)="getQuotesFromLiseuse()" />
            &nbsp; Include Liseuse
          </div>
        </label>
      </div>


     

      <div class="row" style="display:flex;">
        <div class="col-md-8" *ngIf="!isVisibleQuotes">
          <div style="height: 400px;overflow: auto;">
            <table style='margin: 0px;' mat-table #parentSort="matSort" [dataSource]="dataSource.Data" class="mat-elevation-z8 overflow-x-auto" matSort (matSortChange)="sortParentData($event)" (dragover)="onDragOver($event)"
                   (dragleave)="onDragLeave($event)"
                   (drop)="onDrop($event)"
                  >

              <ng-container matColumnDef="isin">
                <th mat-header-cell *matHeaderCellDef style="width:  15%;"> ISIN </th>
                <td mat-cell *matCellDef="let element" (click)="disableSelection();"
                    [style.background]="(element?.Status == 'UNACK')? '#ffa64d' : ''">
                  <a style="text-decoration: underline;color: black;cursor: pointer;" (click)="openProduct(element.ISIN)">{{element.ISIN.startsWith("XX TBD") ? "New Issue" : element.ISIN}}</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element"
                    [style.background]="(element?.Status == 'UNACK')? '#ffa64d' : ''">
                  {{element.Title}}
                </td>
              </ng-container>

              <ng-container matColumnDef="quotenbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Quote Nbr </th>
                <td mat-cell *matCellDef="let element"> {{element.QuoteNbr}} </td>
              </ng-container>

              <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef> Client </th>
                <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
              </ng-container>

              <ng-container matColumnDef="requestdate">
                <th mat-header-cell *matHeaderCellDef> Request Date </th>
                <td mat-cell *matCellDef="let element"> {{ element.RowCreatedOn }} </td>
              </ng-container>

              <ng-container matColumnDef="maturity">
                <th mat-header-cell *matHeaderCellDef> Maturity </th>
                <td mat-cell *matCellDef="let element"> {{element.Maturity}} Months</td>
              </ng-container>

              <ng-container matColumnDef="sales">
                <th mat-header-cell *matHeaderCellDef style="width:  6%;"> Sales </th>
                <td mat-cell *matCellDef="let element"> {{ element.RowCreatedBy ? element.RowCreatedBy : element.RowModifiedBy }} </td>
              </ng-container>


              <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef style="width:  6%;"> Currency </th>
                <td mat-cell *matCellDef="let element"> {{element.Currency}} </td>
              </ng-container>

              <ng-container matColumnDef="underlyings">
                <th mat-header-cell *matHeaderCellDef> Underlyings </th>
                <td mat-cell *matCellDef="let element"
                    [style.background]="(element.Product?.ExternalUnderlyingsCurrency)? '#3dcdef' : ''">
                  {{element.Undelyings}}
                </td>
              </ng-container>

              <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef> Size </th>
                <td mat-cell *matCellDef="let element"> {{element.Size}} </td>
              </ng-container>

              <ng-container matColumnDef="side">
                <th mat-header-cell *matHeaderCellDef style="width:  5%;"> Side </th>
                <td mat-cell *matCellDef="let element"> {{element.Side}} </td>
              </ng-container>

              <!--<ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef > Is Primary </th>
            <td mat-cell  *matCellDef="let element"> {{element.IsPrimaryQuote}} </td>
          </ng-container>-->

              <ng-container matColumnDef="status">
                l
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="width: 15px;"> Action </th>
                <td mat-cell *matCellDef="let element">
                  <button [disabled]="group =='middle-office' || group=='compliance-risk'" mat-button [matMenuTriggerFor]="menu" (click)="disableSelection()">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="cloneQuote(element)">Clone</button>
                    <button mat-menu-item (click)="hideQuote(element)">Hide</button>
                    <!--<button mat-menu-item (click)="ExportToExcel(element.QuoteNbr)">export</button>-->
                    <button mat-menu-item (click)="deletequote(element,true)" *ngIf="(group =='trader' || group =='super-user') && (element.Status =='Cancelled' && element.ToBeDeleted)">Delete Parent</button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" [hidden]="hiddenQuotes.indexOf(row.QuoteNbr)>=0" class="table-row" (click)="OnSelect(row)" [matTooltip]="getQuoteChildNbr(row.QuoteChilds)" matTooltipId matTooltipClass="stl-tooltip" matTooltipPosition="above"
                  [style.background]="row.Selected ? 'lightblue' : row.Color"></tr>
            </table>
          </div>
          <mat-paginator #paginator="matPaginator" [length]="dataSource.TotalRecords" [pageSize]="dataSource.PageSize"
                         [pageSizeOptions]="[50, 10, 20 ]" (page)="pageEvent= $event; onPaginateChange($event)" showFirstLastButtons>
          </mat-paginator>
        </div>
        <fieldset class="col-md-4" *ngIf="selectedQuoteChild" style="margin-top: 0px;">
          <!--<div class="col-md-2" *ngIf="(group =='trader' || group =='super-user')">
        <button mat-raised-button color="primary" (click)="ReadMail()">Read my mail</button>
      </div>
      <div class="col-md-6">
      </div>
      <div class="col-md-2" *ngIf="(group =='trader' || group =='super-user')">
        <button mat-raised-button color="primary" (click)="SendPrice()">Send Price(s)</button>
      </div>-->
          <!-- Pricing (QuoteChildIssuer) -->
          <div class="col-md-12" style="height: 400px;overflow: auto; ">
            <table mat-table #priceSort="matSort" [dataSource]="dataSourcePrice" matSort (matSortChange)="sortData($event)"
                   matSortActive="SolveFor" matSortDirection="{{matsortdirection}}" matSortDisableClear class="mat-elevation-z8 overflow-x-auto">

              <ng-container matColumnDef="IssuerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Issuer Code</th>
                <td mat-cell *matCellDef="let row">{{row?.IssuerName}}</td>
              </ng-container>

              <ng-container matColumnDef="SolveFor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Solve For</th>
                <td mat-cell *matCellDef="let row" (dblclick)="func2()"> {{row?.SolveForValue}}</td>
              </ng-container>

              <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Comment</th>
                <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.Comment}}" style="width: 25%;">
                  {{row?.Comment}}
                </td>
              </ng-container>

              <ng-container *ngIf="group =='trader' || group =='super-user'" matColumnDef="counterpartid">
                <th mat-header-cell *matHeaderCellDef>Cp ID</th>
                <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.CounterpartId}}">
                  {{row?.CounterpartId}}
                </td>
              </ng-container>

              <ng-container *ngIf="group =='trader' || group =='super-user'" matColumnDef="autopricemessage">
                <th mat-header-cell *matHeaderCellDef>Ap Message</th>
                <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.AutoPriceMessage}}">
                  {{row?.AutoPriceMessage}}
                </td>
              </ng-container>

              <ng-container *ngIf="group =='sales' || group =='super-user' || group =='middle-office' || group =='compliance-risk'" matColumnDef="LastUpdate">
                <th mat-header-cell *matHeaderCellDef>Last Update</th>
                <td mat-cell *matCellDef="let row"> {{row?.LastUpdate}}</td>
              </ng-container>


              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-button [matMenuTriggerFor]="menu" (click)="disableSelection()" [disabled]="group !='trader' && group != 'super-user'">
                    <mat-icon>library_add</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="addNew()">Add</button>
                    <button mat-menu-item (click)="addNewBatch()">Add Batch</button>
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let row; let i=index;">
                  <button mat-button [matMenuTriggerFor]="menu" (click)="disableSelection()" [disabled]="group !='trader' && group != 'super-user'">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="startEdit(i,row,row.Id, row.IssuerCode,row.IssuerName, row.SolveFor, row.Comment,row.PriceOff)">Edit</button>
                    <button mat-menu-item (click)="deleteItem(i,row.Id)">Delete</button>
                    <button mat-menu-item cdkCopyToClipboard="{{(row && row.CounterpartId && row.CounterpartId !='')? row.CounterpartId : ' '}}">
                      copy ID
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <!-- <ng-container *ngIf="group =='trader' || group =='super-user'" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-icon-button title="Add" color="primary" (click)="addNew()">
                <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
              </button>
              <button mat-icon-button title="Add Group" color="primary" (click)="addNewBatch()">
                <mat-icon aria-label="Example icon-button with a heart icon">library_add</mat-icon>
              </button>
            </th>

            <td mat-cell *matCellDef="let row; let i=index;">
              <button mat-icon-button title="Edit" color="primary"
                (click)="startEdit(i,row,row.Id , row.IssuerCode,row.IssuerName, row.SolveFor, row.Comment)">
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>

              <button mat-icon-button title="Delete" color="primary" (click)="deleteItem(i,row.Id)">
                <mat-icon aria-label="Delete">delete</mat-icon>
              </button>
            </td>
          </ng-container> -->

              <tr mat-header-row *matHeaderRowDef="displayedColumnPrices; sticky: true" class="table-header"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnPrices;" class="table-row" (dblclick)="(group =='trader' || group =='super-user') ? startEdit(i,row,row.Id, row.IssuerCode,row.IssuerName, row.SolveFor, row.Comment,row.PriceOff) : noResponse()"></tr>

            </table>

            <!-- <mat-paginator #pricesPaginator="matPaginator" [pageSizeOptions]="[15, 10, 20]" showFirstLastButtons></mat-paginator> -->

            <div class="no-results" [style.display]="quoteChildIssuers?.length == 0 ? '' : 'none'">
              No results
            </div>

            <!--<mat-paginator #pricesPaginator="matPaginator"
                       [pageSize]="15"
                       [pageSizeOptions]="[15, 10, 20]"
                       [length]="pricesLength"
                       (page)="pageEvent= $event; onPaginateChange($event)"
                       showFirstLastButtons>
        </mat-paginator>-->

          </div>

          <div *ngIf="selectedQuoteChild && selectedQuoteChild.Comment && selectedQuoteChild.Comment != ''"
               style="font-size: 10px; padding:10px; border: 2px solid #383737; background: #f3f3f3;">
            <h4>Comment :</h4>

            <div [innerHTML]="selectedQuoteChild.Comment"></div>
          </div>
        </fieldset>
      </div>
    </div>

  </div>
  <br />
  <div class="row">
    <fieldset class="col-md-9">

      <div *ngIf="quoteChilds.length > 0">
        <div class="row">
          <div class="row" style="display:flex;">
            <h4>Blotter Child</h4>
            <button mat-button (click)="displayQuoteChilds()">
              <mat-icon *ngIf="isVisibleQuoteChilds">expand_more</mat-icon>
              <mat-icon *ngIf="!isVisibleQuoteChilds">expand_less</mat-icon>
            </button>
          </div>
          <div class="col-md-2" *ngIf="(group =='sales' || group =='super-user') && (selectedQuoteChild && (selectedQuoteChild.Status =='Price(s) sent' || selectedQuoteChild.Status =='Created') && ((!selectedQuoteChild.IsProductDraft && selectedQuoteChild.Title == 'MiniFuture') || ( selectedQuoteChild.Title !== 'MiniFuture')))">
            <mat-form-field appearance="fill">
              <mat-label>Sales</mat-label>
              <mat-select [(ngModel)]="onBehalfSale">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let sale of allSales" [value]="sale.SalesCode">
                  {{sale.SalesCode}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div style="height: 200px;overflow: auto;"  *ngIf="!isVisibleQuoteChilds">
          <table style='font-size: 10px; margin: 0px; color: #863F2B;' mat-table
                 [dataSource]="dataSourceChild" class="mat-elevation-z8 overflow-x-auto">
            <ng-container matColumnDef="quotechildnbr">
              <th mat-header-cell *matHeaderCellDef> Quote Child Nbr </th>
              <td mat-cell *matCellDef="let element">{{element.QuoteChildNbr}}</td>
            </ng-container>

            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef> Client </th>
              <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
            </ng-container>

            <ng-container matColumnDef="sales">
              <th mat-header-cell *matHeaderCellDef> Sales </th>
              <td mat-cell *matCellDef="let element"> {{element.Sales}} </td>
            </ng-container>

            <ng-container matColumnDef="trader">
              <th mat-header-cell *matHeaderCellDef> Trader </th>
              <td mat-cell *matCellDef="let element"> {{element.Trader}} </td>
            </ng-container>

            <!--<ng-container matColumnDef="onbehalf">
              <th mat-header-cell *matHeaderCellDef> OnBehalf </th>
              <td mat-cell *matCellDef="let element"> {{element.OnBehalf}} </td>
            </ng-container>-->

            <ng-container matColumnDef="isin">
              <th mat-header-cell *matHeaderCellDef> ISIN </th>
              <td mat-cell *matCellDef="let element" (click)="disableSelection();"><a style="text-decoration: underline;color: black;cursor: pointer;" (click)="openProduct(element.ISIN)"> {{element.ISIN}} </a> </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell *matCellDef="let element"> {{element.Title}} </td>
            </ng-container>

            <ng-container matColumnDef="side">
              <th mat-header-cell *matHeaderCellDef> Side </th>
              <td mat-cell *matCellDef="let element"> {{element.Side}} </td>
            </ng-container>

            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef> Size </th>
              <td mat-cell *matCellDef="let element"> {{element.Size}} </td>
            </ng-container>

            <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef> Currency </th>
              <td mat-cell *matCellDef="let element"> {{element.Quote.IsPrimaryQuote ? element.Product?.CurrencyCode.toUpperCase() :element.Quote.Currency}} </td>
            </ng-container>

            <ng-container matColumnDef="maturity">
              <th mat-header-cell *matHeaderCellDef> Maturity </th>
              <td mat-cell *matCellDef="let element"> {{element.Product?.Maturity}} Months</td>
            </ng-container>

            <ng-container matColumnDef="underlyings">
              <th mat-header-cell *matHeaderCellDef> Underlyings </th>
              <td mat-cell *matCellDef="let element"> {{element.Product?.Underlyings}} </td>
            </ng-container>

            <!--<ng-container matColumnDef="underlyings">
              <th mat-header-cell *matHeaderCellDef> Underlyings </th>
              <td mat-cell *matCellDef="let element"> {{element.Product.Underlyings}} </td>
            </ng-container>-->

            <ng-container matColumnDef="solvefor">
              <th mat-header-cell *matHeaderCellDef> SolveFor </th>
              <td mat-cell *matCellDef="let element"> {{element.SolveFor}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
            </ng-container>


            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <button [disabled]="group =='middle-office' || group=='compliance-risk'" mat-button [matMenuTriggerFor]="menu" (click)="disableSelection()">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button *ngIf="element.Status =='UNACK' || element.Status =='Requested'" mat-menu-item
                          (click)="setQuoteToUser(element)">
                    This is my quote
                  </button>
                  <button mat-menu-item (click)="cloneQuoteChild(element)">Clone Quote</button>
                  <button *ngIf="element.Status =='UNACK'" mat-menu-item (click)="modifyQuote(element)">
                    Modify
                    Quote
                  </button>
                  <button *ngIf="(group =='sales' || group =='super-user') && (element.Status =='Price(s) sent' || element.Status =='Created') && ((!element.IsProductDraft && element.Title == 'MiniFuture') || ( element.Title !== 'MiniFuture'))"
                          mat-menu-item (click)="sendOrder(element)">
                    Send Order
                  </button>
                  <button mat-menu-item (click)="cancelQuote(element)">Cancel Quote</button>
                  <button *ngIf="(group =='trader' || group =='super-user') && (element.Status =='UNACK' || element.Status =='Requested' || element.Status =='Price(s) sent') && element.Title == 'MiniFuture'"
                          mat-menu-item (click)="createMinifuture(element)">
                    Create Minifuture
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && element.Title !== 'MiniFuture' && element.Quote.IsPrimaryQuote"
                          [disabled]="group =='trader' && selectedQuoteChild && selectedQuoteChild.Status =='Order sent'"
                          mat-menu-item (click)="automatedPricer(element)">
                    Automated Pricer
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && element.Title !== 'MiniFuture' && element.Quote.IsPrimaryQuote"
                          [disabled]="group =='trader' && selectedQuoteChild && selectedQuoteChild.Status =='Order sent'"
                          mat-menu-item (click)="ReadMail()">
                    Read Prices
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && element.Status =='Order sent'"
                          mat-menu-item (click)="setOrderReceived(element)">
                    Order Received
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && (element.Status =='Price(s) sent' ||element.Status =='Cancelled') "
                          mat-menu-item (click)="SetStatusRequested(element)">
                    Set Status Requested
                  </button>
                  <button *ngIf="(group =='sales' || group =='super-user') && (element.Status =='Order sent' || element.Status =='Order received')"
                          mat-menu-item (click)="editOrder(element)">
                    Modify Order
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && (element.Status =='Cancelled' && element.ToBeDeleted)"
                          mat-menu-item (click)="deletequote(element,false)">
                    Delete Quote
                  </button>
                  <!--<button mat-menu-item (click)="makeFactsheet(element)">Make Factsheet</button>-->
                  <!--<button mat-menu-item (click)="cancelQuote(element)">Cancel Quote</button>-->
                  <!--<button mat-menu-item (click)="openNpl(element)">NPL</button>-->
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedChildColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: ['quotechildnbr', 'client', 'sales', 'trader', 'isin', 'title', 'side', 'size', 'currency', 'maturity','underlyings', 'solvefor','status', 'actions'];"
                class="table-row" (click)="OnSelectChild(row)" [style.background]="row.Selected ? 'lightblue' : row.Color"  [matTooltip]="getTooltipContent(row.Description)"  matTooltipClass="styling-tooltip"  matTooltipPosition="above">
            </tr>
          </table>
        </div>
        <mat-paginator #childsPaginator="matPaginator" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons>
        </mat-paginator>
      </div>
      <div *ngIf="deals.length > 0">
        <div class="row">
          <div class="row" style="display:flex;">
            <h4>Trade Blotter</h4>
            <button mat-button (click)="displayTrades()">
              <mat-icon *ngIf="isVisibleTrades">expand_more</mat-icon>
              <mat-icon *ngIf="!isVisibleTrades">expand_less</mat-icon>
            </button>
          </div>
          <div *ngIf="(group =='sales' || group =='super-user') && selectedDeal && selectedDeal.Status =='Done to be booked'">
            <mat-form-field appearance="fill">
              <mat-label>Sales</mat-label>
              <mat-select [(ngModel)]="onBehalfSaleBook">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let sale of allSales" [value]="sale.SalesCode">
                  {{sale.SalesCode}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div style="height: 200px;overflow: auto;"  *ngIf="!isVisibleTrades">
          <table style='margin: 0px;color: #863F2B;'
                 mat-table [dataSource]="dataSourceDeal" class="mat-elevation-z8 overflow-x-auto">
            <ng-container matColumnDef="dealnbr">
              <th mat-header-cell *matHeaderCellDef> Deal Nbr </th>
              <td mat-cell *matCellDef="let element" (click)="disableSelection()"><a style="text-decoration: underline;color: black;cursor: pointer;" (click)="openDeal(element.DealNbr)">{{element.DealNbr}}</a></td>
            </ng-container>

            <ng-container matColumnDef="quotechildnbr">
              <th mat-header-cell *matHeaderCellDef> Quotechild Nbr </th>
              <td mat-cell *matCellDef="let element">{{element.QuoteChildNbr}}</td>
            </ng-container>

            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef> Client </th>
              <td mat-cell *matCellDef="let element"> {{element.Client}} </td>
            </ng-container>

            <ng-container matColumnDef="trader">
              <th mat-header-cell *matHeaderCellDef> Trader </th>
              <td mat-cell *matCellDef="let element"> {{element.Trader}} </td>
            </ng-container>

            <ng-container matColumnDef="sales">
              <th mat-header-cell *matHeaderCellDef> Sales </th>
              <td mat-cell *matCellDef="let element"> {{element.Sales}} </td>
            </ng-container>

            <ng-container matColumnDef="side">
              <th mat-header-cell *matHeaderCellDef> Side </th>
              <td mat-cell *matCellDef="let element"> {{element.Side}} </td>
            </ng-container>

            <ng-container matColumnDef="isin">
              <th mat-header-cell *matHeaderCellDef> Isin </th>
              <td mat-cell *matCellDef="let element" (click)="disableSelection()"> <a style="text-decoration: underline;color: black;cursor: pointer;" (click)="openProduct(element.ISIN)">{{element.ISIN}} </a> </td>
            </ng-container>

            <ng-container matColumnDef="issuer">
              <th mat-header-cell *matHeaderCellDef> Issuer </th>
              <td mat-cell *matCellDef="let element">
                {{element.Issuer}}
              </td>
            </ng-container>


            <ng-container matColumnDef="titre">
              <th mat-header-cell *matHeaderCellDef> Titre </th>
              <td mat-cell *matCellDef="let element">
                {{element.Title}}
              </td>
            </ng-container>
            <ng-container matColumnDef="maturity">
              <th mat-header-cell *matHeaderCellDef> Maturity </th>
              <td mat-cell *matCellDef="let element">
                {{element.Maturity}} Months
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef> Size </th>
              <td mat-cell *matCellDef="let element">
                {{element.Size}}
              </td>
            </ng-container>
            <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef> Currency </th>
              <td mat-cell *matCellDef="let element">
                {{element.Currency}}
              </td>
            </ng-container>
            <ng-container matColumnDef="underlyings">
              <th mat-header-cell *matHeaderCellDef> Underlyings </th>
              <td mat-cell *matCellDef="let element">
                {{element.Underlyings}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <button mat-button [matMenuTriggerFor]="menu" (click)="disableSelection()">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button *ngIf="group !='middle-office' && group!='compliance-risk'" mat-menu-item cdkCopyToClipboard="{{element.ISIN}}">
                    copy ISIN
                  </button>
                  <button *ngIf="(group =='trader' || group =='super-user') && element.Status =='Order received'"
                          mat-menu-item (click)="doneDeal(element)">
                    Done Deal
                  </button>
                  <button *ngIf="(group =='sales' || group =='super-user') && (element.Status =='Order received' || element.Status =='Order sent')"
                          mat-menu-item (click)="amendToMarket(element)">
                    Amend to Market
                  </button>
                  <button mat-menu-item *ngIf="group !='middle-office' &&  group!='compliance-risk'" (click)="cancelOrder(element)">Cancel Order</button>
                  <button *ngIf="(group =='sales' || group =='super-user') && element.Status =='Done to be booked'"
                          mat-menu-item (click)="bookDeal(element)">
                    Book Deal
                  </button>
                  <button *ngIf="(group =='sales' || group =='super-user')"
                          mat-menu-item (click)="addSize(element)">
                    Add Size
                  </button>
                  <button *ngIf="(group =='sales' || group =='super-user' || group =='trader' || group =='middle-office' || group=='compliance-risk')"
                          mat-menu-item (click)="arborescence(element)">
                    Arborescence
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedDealColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDealColumns;" class="table-row"
                (click)="OnSelectDeal(row)" [style.background]="row.Selected ? 'lightblue' : row.Color"></tr>
          </table>
        </div>
        <mat-paginator #tradesPaginator="matPaginator" [pageSizeOptions]="[10, 5, 25]" showFirstLastButtons>
        </mat-paginator>
      </div>
    </fieldset>
    <fieldset class="col-md-3">
      <div *ngIf="selectedQuoteChild && selectedQuoteChild.Description && selectedQuoteChild.Description != ''"
           style="font-size: 10px; padding:10px; border: 2px solid #383737; background: #f3f3f3;">
        <h4>Description</h4>

        <div [innerHTML]="selectedQuoteChild.Description"></div>
        <br />
        <div *ngIf="selectedQuoteChild.Title == 'Other'"> <a (click)="startEditDescription(selectedQuoteChild)"> Modify Description</a></div>

      </div>
      <div *ngIf="selectedDeal && (selectedDeal.Status =='Order received' || selectedDeal.Status =='Order sent')&& selectedDeal.OrderDescription && selectedDeal.OrderDescription != ''"
           style="font-size: 10px; padding:10px; border: 2px solid #383737; background: #f3f3f3;">
        <h4>Order Description</h4>

        <div [innerHTML]="selectedDeal.OrderDescription"></div>
      </div>
      <div *ngIf="selectedDeal && selectedDeal.Status !=='Order received' && selectedDeal.Status !=='Order sent' && selectedDeal.DealDescription && selectedDeal.DealDescription != ''"
           style="font-size: 10px; padding:10px; border: 2px solid #383737; background: #f3f3f3;">
        <h4>DoneDeal Description</h4>

        <div [innerHTML]="selectedDeal.DealDescription"></div>
      </div>
      <!--<fieldset class="col-md-6">
      </fieldset>-->
    </fieldset>

  </div>


  <!--<div class="footer">
    <div class="col-md-6">
      <div class="form-group col-md-4">
        <button id="tgl" type="button" class="btn btn-primary" (click)="toggleSidebar(true)" *ngIf="hide ==false">Hide sideBar</button>
        <button id="tgl" type="button" class="btn btn-primary" (click)="toggleSidebar(false)" *ngIf="hide ==true">show sideBar</button>
      </div>
    </div>
  </div>-->
</div>
